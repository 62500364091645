<template>
  <div>
    <!-- catches all the outer class definitions -->
    <div class="header-entry" :class="{ 'align-right': alignRight }">
      <div class="first-column">
        <span>{{ title }}</span>
        <span v-if="unit" class="unit">{{ unit }}</span>
      </div>
      <InfoBox v-if="infoboxTitle" :title="infoboxTitle">{{ infoboxText }}</InfoBox>
      <slot name="infobox"> </slot>
    </div>
  </div>
</template>

<script>
import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  name: 'HeaderEntry',
  components: { InfoBox },

  props: {
    title: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: false,
    },
    infoboxTitle: {
      type: String,
      required: false,
    },
    infoboxText: {
      type: String,
      required: false,
    },
    alignRight: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.header-entry {
  display: flex;
  flex-direction: row;
}

.align-right {
  text-align: right;
  justify-content: flex-end;
}

.first-column {
  display: flex;
  flex-direction: column !important;
  align-items: flex-end;
}

.unit {
  color: #888;
  font-weight: 400;
  margin-left: 5px;
}
</style>
